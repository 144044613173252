import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Seo from "../components/seo";
import Logo from "../images/kgw/logo-400.png";
import Photo from "../images/kgw/steve.jpg";
import CalloutSection from "../sections/callout-section";
import HowToDonateSection from "../sections/how-to-donate-section";
import QuoteSection from "../sections/quote-section";
import AboutSection from "../sections/text-section";
import VideoSection from "../sections/video-section";
import Layout from "./layouts/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <Seo title="KGW" mdxType="Seo" />
      <QuoteSection logo={Logo} photo={Photo} text="The KGW Great Toy Drive is truly a community event, and we’re thrilled to once again be expanding the opportunities for giving this year. We’re especially grateful to Regence, iQ Credit Union, Fred Meyer, and the local Toyota Dealers, who join with us in making sure needy families have a chance to bring joy to children on Christmas morning." name="Steve Carter" title="President and General Manager" mdxType="QuoteSection" />
 <HowToDonateSection mdxType="HowToDonateSection" />
  <VideoSection videos={[{
        file: "regence-60.mp4"
      }, {
        file: "iq-toy-bot-30.mp4"
      }, {
        file: "fred-meyer-30.mp4"
      }, {
        file: "toyota-duplicator-30.mp4"
      }, {
        file: "iq-surprise-30.mp4"
      }, {
        file: "toyota-surprise-30.mp4"
      }]} mdxType="VideoSection" />
  
      <AboutSection classes="" mdxType="AboutSection">
        <h1>{`About the KGW Great Toy Drive`}</h1>
        <p>{`The KGW Great Toy Drive, a collection event for more than 35
years, takes place now through December XXX. The goal is to collect
toys for less fortunate families in Oregon and SW Washington. All
donated toys will be distributed with the help of `}<a parentName="p" {...{
            "href": "/non-profits"
          }}>{`over 130 local nonprofit organizations`}</a>{` and hundreds of
dedicated volunteers.`}</p>
      </AboutSection>
      <CalloutSection classes={"green card"} mdxType="CalloutSection">
        <h1>{`Thank you for caring about our community!`}</h1>
      </CalloutSection>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      