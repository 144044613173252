import React from "react";
import * as styles from "./callout-section.module.scss";


const CalloutSection = (props) => {
  const { children, classes } = props;
  console.log("HEY");
  console.log(children);

  // const { chalkboardTexture } = useStaticQuery(graphql`
  //   query ChalkboardSectionQuery {
  //     chalkboardTexture: file(
  //       relativePath: { eq: "chalkboard-seamless-2.jpg" }
  //     ) {
  //       childImageSharp {
  //         fluid(maxWidth: 1920) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <div className={styles.container + " " + classes}>
      <section>{children}</section>
    </div>
  );
};
export default CalloutSection;
