import React from "react";
import * as styles from "./quote.module.scss";

const Quote = (props) => {
  const { photo, text, name, title } = props;

  return (
    <div className={styles.quoteWrapper}>
      <div className={styles.photo + " " + (photo ? "show" : "hide")}>
        <img src={photo} alt="" />
      </div>
      <div className={styles.text + " " + (photo ? "" : styles.noPhoto)}>
        <p className={styles.quoteText}>
          <span>&ldquo;</span>
          {text}
          <span>&rdquo;</span>
        </p>
        <p className={styles.name}>{name}</p>
        <p className={styles.title}>{title}</p>
      </div>
    </div>
  );
};
export default Quote;
