import React from "react";
import ExternalLink from "../components/external-link";
import Quote from "./quote";
import * as styles from "./quote-section.module.scss";

const QuoteSection = (props) => {
  const { logo, logoUrl, photo, text, name, title } = props;

  return (
    <div className={styles.container + " white"}>
      <section>
        {logo && (
          <div className={styles.logo}>
            <ExternalLink href={logoUrl}>
              <img src={logo} alt={name} />
            </ExternalLink>
          </div>
        )}
        {text && <Quote photo={photo} text={text} name={name} title={title} />}
      </section>
    </div>
  );
};
export default QuoteSection;
